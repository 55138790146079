.prize-deck-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.prize-deck-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.prize-odds-mini-preview {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 80px;
}
