.media-card-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.media-card-overflow {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.media-card-question-description {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 20px;
}

.media-card-overflow-emoji {
  text-align: center;
  margin: auto;
  z-index: 999;
}
